// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-project-layout-js": () => import("./../src/components/projectLayout.js" /* webpackChunkName: "component---src-components-project-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-culinaire-sale-js": () => import("./../src/pages/culinaire-sale.js" /* webpackChunkName: "component---src-pages-culinaire-sale-js" */),
  "component---src-pages-culinaire-sucre-js": () => import("./../src/pages/culinaire-sucre.js" /* webpackChunkName: "component---src-pages-culinaire-sucre-js" */),
  "component---src-pages-galeries-js": () => import("./../src/pages/galeries.js" /* webpackChunkName: "component---src-pages-galeries-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institutionnel-js": () => import("./../src/pages/institutionnel.js" /* webpackChunkName: "component---src-pages-institutionnel-js" */),
  "component---src-pages-metiers-js": () => import("./../src/pages/metiers.js" /* webpackChunkName: "component---src-pages-metiers-js" */),
  "component---src-pages-nature-morte-deco-js": () => import("./../src/pages/nature-morte-deco.js" /* webpackChunkName: "component---src-pages-nature-morte-deco-js" */),
  "component---src-pages-spectacle-js": () => import("./../src/pages/spectacle.js" /* webpackChunkName: "component---src-pages-spectacle-js" */)
}

